<template>
  <div class="add-basic">
    <el-form-item>
      <span class="yk-label__require" slot="label">商品名称</span>
      <el-input placeholder="请输入商品名称" v-model="value.name" rows="3" maxlength="12"
                show-word-limit>
      </el-input>
    </el-form-item>
    <el-form-item>
      <span slot="label">商品简述</span>
      <el-input type="textarea" placeholder="请输入商品简述" v-model="value.description" rows="3" maxlength="40"
                show-word-limit>
      </el-input>
    </el-form-item>
    <el-form-item>
      <span class="yk-label__require" slot="label">商品分类</span>
      <el-popover
        placement="right"
        trigger="hover"
      >
        <sort-select :select="value.sortUuid"
                     ref="sort"
                     :shop-use="value.shopUse"
                     @select="(v) => {value.sortUuid = v.uuid, value.sortName = v.name}"></sort-select>
        <el-button type="primary" plain size="small"
                   style="width: 120px" slot="reference">{{value.sortName === ''? '选取分类':value.sortName}}</el-button>
      </el-popover>
      <span class="add-basic-refresh">
        <a target="_blank">
          <el-button type="text">
            新建分类
          </el-button>
        </a>
        <span class="split">|</span>
        <el-button type="text" @click="getSorts" class="he-class__button-new">
          刷新
        </el-button>
      </span>
    </el-form-item>
    <el-form-item>
      <span class="yk-label__require" slot="label">商品轮播图</span>
      <material-manager v-model="value.slides" :limit="6" @change="(url) => {
        value.slides = url
      }"></material-manager>
      <p class="tips" style="padding-left: 0; clear: both;">建议尺寸：800*800,可拖拽改变图片顺序，首张图为首图，最多上传6张</p>
    </el-form-item>

    <el-form-item label="是否添加视频">
      <el-switch v-model="value.isVideo" :active-value="2" :inactive-value="1"></el-switch>
    </el-form-item>
    <template v-if="value.isVideo === 2">
      <el-form-item>
        <span class="yk-label__require" slot="label">主视频</span>
        <material-manager v-model="value.video" @change="(url) => value.video=url" :limit="1"
                          :file-type="'video'"
                          :multiple="false"></material-manager>
        <p class="tips" style="padding-left: 0">建议时长：10~30秒，宽高比 16:9</p>
      </el-form-item>
      <el-form-item>
        <span class="yk-label__require" slot="label">视频封面</span>
        <material-manager v-model="value.videoCover" @change="(url) => value.videoCover=url" :limit="1"
                          :multiple="false"></material-manager>
      </el-form-item>
    </template>

  </div>
</template>
<script>

  import SortSelect from "./select";

  export default {
    name: 'addBasic',
    components: {
      SortSelect
    },
    props: {
      value: {
        type: Object
      }
    },
    data() {
      return {

      }
    },
    mounted() {
      this.getSorts()
    },
    methods: {
      getSorts() {
       this.$refs.sort.getSorts()
      }
    }
  }
</script>
<style lang="scss">
  .add-basic {
    .el-textarea .el-textarea__inner {
      resize: none;
    }

    .tips {
      @include font-little();
      color: $tip-text-color;
    }

    &-refresh {
      color: $theme-color;
      margin-left: $small-space;

      .split {
        display: inline-block;
        margin: 0 4px;
      }
    }
  }
</style>
