<template>
  <div>
    <div class="sku-wrap">
      <div class="sku-gengertor" v-if="param.sku && param.sku.length>0" v-show="!disabled">
        <draggable
          v-model="param.sku"
          v-bind="{
                            sortable: true,
                            animation: 150,
                            ghostClass:'ghost',
                            dragClass:'dragging',
                            chosenClass:'dragging',
                            pull: 'clone'
                          }"
          tag="ul"
          handle=".handle"
          @end="handleDragSkuEnd"
        >
          <li v-for="(item,index) in param.sku" :key="index">
            <div v-if="canEditSpu" class="close" @click="handleDelSkuGenerator(index,item)">
              <i class="el-icon-close"></i>
            </div>
            <div class="sku-key">
              <span class="sku_label">规格名：</span>
              <el-input style="width: 180px;" v-model="item.name" @focus="handleSkuFocus(item)"
                        @blur="handleSkuBlur(item)"></el-input>
            </div>
            <div class="sku-value">
              <span class="sku_label">规格值：</span>
              <draggable v-model="item.values"
                         v-bind="{ sortable: true,  animation: 150,  ghostClass:'ghost'}"
                         tag="span"
                         handle=".handle"
                         @end="handleDragSkuValueEnd"
              >
                            <span class="sku-value-item" v-for="(skuValue,skuIndex) in item.values"
                                  :key="`skuValue${skuIndex}`">
                                <el-input style="width: 180px;" v-model="skuValue.name"
                                          @focus="handleCacheFocus(item,skuValue)"
                                          @change="handleSkuValueChange(item,skuValue)">
                                </el-input>

                              <span
                                class="sku-value-close"
                                @click="handleDelGeneratorValue(item,skuIndex,skuValue)"
                              >
                                  <i class="el-icon-close"></i>
                                </span>
                                <span class="handle">
                                    <i class="el-icon-rank"></i>
                                </span>
                              </span>
              </draggable>
              <el-button type="text" @click="handleAddGeneratorValue(item)">添加</el-button>
            </div>
          </li>
        </draggable>
      </div>
      <div>
        <div v-if="canEditSpu">
          <el-button type="default" plain
                     @click="handleMakeSkuGenerator">
            添加规格
          </el-button>
        </div>
        <div v-else>
          <p class="tips">注：添加或删除规格名，须重新创建货品，可通过“复制货品”快捷操作</p>
        </div>
      </div>
    </div>
    <div class="sku-wrap" v-loading="loading">
      <el-table class="idefine-table sku-table" border :data="param.spu" :span-method="rowSpanMethod">
        <el-table-column
          align="center"
          v-for="(item,index) in skuDetailColumns"
          :key="index"
          :label="item.name"
          :property="item.name"
        ></el-table-column>
        <el-table-column label="图片" property="price" width="60px">
          <template slot-scope="scope">
            <iyy-upload class="small" :is-radio="true" :file-list.sync="scope.row.thumb"></iyy-upload>
          </template>
        </el-table-column>
        <el-table-column label="价格" width="80px">
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.batchPrice}}
            </template>
            <template v-else>
              <el-input type="text" v-model="scope.row.batchPrice"></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="库存" width="80px">
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.batchPrice}}
            </template>
            <template v-else>
              <el-input type="text" v-model="scope.row.batchPrice"></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="成本价(元)" width="80px">
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.batchPrice}}
            </template>
            <template v-else>
              <el-input type="text" v-model="scope.row.batchPrice"></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="重量(kg)" width="80px">
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.batchPrice}}
            </template>
            <template v-else>
              <el-input type="text" v-model="scope.row.batchPrice"></el-input>
            </template>
          </template>
        </el-table-column>
        <el-table-column label="编码" width="160px">
          <template slot-scope="scope">
            <template v-if="disabled">
              {{scope.row.batchPrice}}
            </template>
            <template v-else>
              <el-input type="text" v-model="scope.row.batchPrice"></el-input>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <div v-show="!disabled">
        批量设置：
        <el-button type="text" @click="handlePriceSet('batchPrice')">批发价</el-button>
        <el-button type="text" @click="handlePriceSet('retailPrice')">零售价</el-button>
        <el-button type="text" @click="handlePriceSet('costPrice')">成本价</el-button>
        <el-button type="text" @click="handleEditRate">修改税率</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import draggable from 'vuedraggable'
  // import IyyUpload from 'common/components/IyyUpload'
  // import { EarlyAlarmInfo } from '@/api/wms/goods'
  // import { GetBasicSet } from '@/api/wms/set'

  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {},
    data() {
      return {
        param: {
          sku: []
        },
        canEditSpu: false,
        taxRate: 0,
        limitSw: 0,
        upperSw: 0,
        staticSw: 0,
        canSetAlarm: true,
        skuDetailColumns: [],
        ObjSpanArr: {},
        pos: 0,
        flag: 0,
        loading: true,
        currentItem: {},
        currentSku: {},
        isSkuAdd: true, // 是否是新增规格值
        cacheSpu: []
      }
    },
    methods: {
      initAlarmInfo() {
        // EarlyAlarmInfo().then(res => {
        //   if (res.code === 0) {
        //     const { limitSw, upperSw, staticSw } = res.data
        //     this.canSetAlarm = limitSw === 0 && upperSw === 0 && staticSw === 0
        //     this.limitSw = limitSw
        //     this.upperSw = upperSw
        //     this.staticSw = staticSw
        //   }
        // })
      },
      // 添加规格
      handleMakeSkuGenerator() {
        const spu = {
          name: '',
          values: []
        }
        this.param.sku.push(spu)
      },
      // 删除规格
      // 规格整个删除 重新加载规格明细
      handleDelSkuGenerator(index, item) {
        this.param.sku.splice(index, 1)
        this.skuDetailColumns.splice(index, 1)
        if (item.values.length > 0) {
          this._watchGenerator()
        }
      },
      // 拖拽规格 重新加载规格明细
      handleDragSkuEnd() {
        this._watchGenerator()
      },
      // 拖拽规值 对比加载规格明细
      handleDragSkuValueEnd() {
        this._watchGeneratorParam(this.param.sku)
        const result = {}
        this.param.spu.forEach(v => {
          result[v.specialItem] = v
        })
        this.cacheSpu.sort((a, b) => {
          return a.index.localeCompare(b.index)
        })
        this.param.spu = this.cacheSpu.map(v => ({...result[v.specialItem], index: v.index}))
      },
      // 规格值输入框获取焦点时进行排序
      handleCacheFocus(item, skuValue) {
        this.currentSku = JSON.parse(JSON.stringify(skuValue))
        // 获取焦点时如果是名称是空则就判定为是新增的规格值
        if (skuValue.name === '') {
          this.isSkuAdd = true
        } else {
          this.isSkuAdd = false
        }
      },
      handleSkuFocus(item) {
        this.oldSkuName = item.name
      },
      // 规格值失去焦点时
      handleSkuBlur(item) {
        const arr = this.param.sku.filter(v => v.name === item.name)
        if (arr.length > 1) {
          this.$message.error('规格名称不能重复')
          item.name = ''
          return
        }
        if (item.values.length > 0) {
          // 修改规格名匹配规格列表
          if (this.oldSkuName.length > 0) {
            this.skuDetailColumns = this.param.sku
            const oldKv = this.oldSkuName
            const kv = item.name
            if (oldKv !== kv) {
              this.param.spu.forEach(v => {
                if (v.specialItem.indexOf(oldKv) >= 0) {
                  this.$set(v, kv, v[oldKv])
                  v.specialItem = v.specialItem.replace(oldKv, kv)
                  delete v[oldKv]
                }
              })
            }
            // this._watchGeneratorParam()
            return
          }
          this._watchGenerator()
        }
      },
      // 添加规格值
      handleAddGeneratorValue(item) {
        item.values.push({name: '', thumb: ''})
        this.currentItem = JSON.parse(JSON.stringify(item))
      },
      // 删除规格值
      handleDelGeneratorValue(item, index, skuValue) {
        if (item.values.length === 1) {
          this.$message.error('至少保留一个规格值')
          return
        }
        item.values.splice(index, 1)
        for (let i = 0; i < this.param.spu.length; i++) {
          if (this.param.spu[i][item.name] === skuValue.name) {
            this.param.spu.splice(i, 1)
            i--
          }
        }
        this.ObjSpanArr = {}
        this.skuDetailColumns.forEach(v => {
          this._GeneratorSpanArr(this.param.spu, v.name)
        })
      },
      // 当规格参数改变时重新生成规格详细参数
      handleSkuValueChange(item, skuValue) {
        const arr = item.values.filter(v => v.name === skuValue.name)
        if (arr.length > 1) {
          this.$message.error('规格名称不能重复')
          skuValue.name = ''
          return
        }
        // 为保证获取焦点时，是否为新增的判定正确，则name为空时认定是删除了规格值
        if (skuValue.name === '') {
          const index = item.values.indexOf(skuValue)
          this.handleDelGeneratorValue(item, index, this.currentSku)
          return
        }
        // 先判断是否为新增规格值
        if (this.isSkuAdd) {
          // 如果是新增规格值
          // 判断是否是新增规格的第一个，如果是则重新生成规格列表
          if (item.values.length === 1) {
            this._watchGenerator()
          } else {
            const sku = this.param.sku
            this._watchGeneratorParam(sku)
            const result = {}
            this.param.spu.forEach(v => {
              result[v.specialItem] = v
            })
            this.cacheSpu.forEach(v => {
              if (!result[v.specialItem]) {
                result[v.specialItem] = v
              }
            })
            this.param.spu = Object.values(result).sort((a, b) => {
              return a.index.localeCompare(b.index)
            })
            this.skuDetailColumns.forEach(v => {
              this._GeneratorSpanArr(this.param.spu, v.name)
            })
          }
        } else {
          const oldKv = `"${item.name}":"${this.currentSku.name}"`
          const kv = `"${item.name}":"${skuValue.name}"`
          if (oldKv !== kv) {
            this.param.spu.forEach(v => {
              if (v.specialItem.indexOf(oldKv) >= 0) {
                v[item.name] = skuValue.name
                v.specialItem = v.specialItem.replace(oldKv, kv)
              }
            })
          }
        }
      },
      // 合并行
      rowSpanMethod({rowIndex, columnIndex}) {
        try {
          if (columnIndex < this.skuDetailColumns.length) {
            const _row = this.ObjSpanArr['arr' + this.skuDetailColumns[columnIndex].name][rowIndex]
            const _col = _row > 0 ? 1 : 0
            return {
              rowspan: _row,
              colspan: _col
            }
          }
        } catch (e) {
          console.log(e)
        }
      },

      // 监听规格构造数组
      _watchGenerator() {
        const generator = this.param.sku
        const columns = this.skuDetailColumns
        const temp = {
          thumb: '',
          retailPrice: 0,
          costPrice: 0,
          batchPrice: 0,
          taxRate: this.taxRate,
          aftertaxRetailPrice: 0,
          aftertaxCostPrice: 0,
          spceificationCode: '',
          stockAlarmSw: 0,
          alarmLimit: '',
          alarmUpper: '',
          maxRestTime: '',
          specialItem: [],
          id: [],
          index: []
        }
        const len = generator.length
        generator.forEach(v => {
          // 生成列数组
          if (columns.indexOf(v) < 0) {
            columns.push(v)
          }
        })
        this._GeneratorSkuDetail({len, row: temp})
        this.flag = 0
        columns.forEach(v => {
          this._GeneratorSpanArr(this.param.spu, v.name)
        })
      },
      // 用户规格值新增时缓存数据
      // 监听规格构造数组
      _watchGeneratorParam(sku) {
        const generator = sku
        const temp = {
          thumb: '',
          retailPrice: 0,
          costPrice: 0,
          batchPrice: 0,
          taxRate: this.taxRate,
          aftertaxRetailPrice: 0,
          aftertaxCostPrice: 0,
          spceificationCode: '',
          stockAlarmSw: 0,
          alarmLimit: '',
          alarmUpper: '',
          maxRestTime: '',
          specialItem: [],
          id: [],
          index: []
        }
        const len = generator.length

        this._GeneratorSkuDetailParam({len, row: temp}, sku)
      },
      // 递归全排列生成商品规格
      _GeneratorSkuDetail({startNo = 0, len, row}) {
        if (len === 0) {
          this.param.spu = []
          return
        }
        if (startNo === 0) {
          this.param.spu = []
        }

        this.param.sku[startNo].values.forEach((v, i) => {
          if (this.flag === len - 1) {
            const endIndex = startNo - row.specialItem.length === 0 ? row.specialItem.length : startNo - row.specialItem.length
            row.specialItem = row.specialItem.slice(0, endIndex)
            row.id = row.id.slice(0, endIndex)
            row.index = row.index.slice(0, endIndex)
          }
          if (startNo === 0) {
            row.specialItem.push(`{"${this.param.sku[startNo].name}":"${v.name}",`)
          } else {
            row.specialItem.push(`"${this.param.sku[startNo].name}":"${v.name}",`)
          }
          row[this.param.sku[startNo].name] = v.name
          row.id.push(Math.pow(256, startNo) * i)
          row.index.push(`${startNo}${i}`)
          // row.index = `${}`
          if (startNo < len - 1) {
            this._GeneratorSkuDetail({startNo: startNo + 1, len, row})
          } else {
            let tempId = 0
            row.id.forEach(v => {
              tempId += v
            })
            const tempSkuText = row.specialItem.join('').slice(0, -1) + '}'
            const index = row.index.join('')
            this.param.spu.push({...row, id: tempId, specialItem: tempSkuText, index})
            this.flag = startNo
          }
        })
      },
      // 递归全排列生成商品规格
      _GeneratorSkuDetailParam({startNo = 0, len, row}, sku) {
        if (len === 0) {
          sku = []
          return
        }
        if (startNo === 0) {
          this.cacheSpu = []
        }
        sku[startNo].values.forEach((v, i) => {
          if (this.flag === len - 1) {
            const endIndex = startNo - row.specialItem.length === 0 ? row.specialItem.length : startNo - row.specialItem.length
            row.specialItem = row.specialItem.slice(0, endIndex)
            row.id = row.id.slice(0, endIndex)
            row.index = row.index.slice(0, endIndex)
          }
          if (startNo === 0) {
            row.specialItem.push(`{"${sku[startNo].name}":"${v.name}",`)
          } else {
            row.specialItem.push(`"${sku[startNo].name}":"${v.name}",`)
          }
          row[sku[startNo].name] = v.name
          row.id.push(Math.pow(256, startNo) * i)
          row.index.push(`${startNo}${i}`)
          if (startNo < len - 1) {
            this._GeneratorSkuDetailParam({startNo: startNo + 1, len, row}, sku)
          } else {
            let tempId = 0
            row.id.forEach(v => {
              tempId += v
            })
            const tempSkuText = row.specialItem.join('').slice(0, -1) + '}'
            const index = row.index.join('')
            this.cacheSpu.push({...row, id: tempId, specialItem: tempSkuText, index})
            this.flag = startNo
          }
        })
      },
      _GeneratorSpanArr(data, key) {
        this.ObjSpanArr['arr' + key] = []
        this.pos = 0
        for (var i = 0; i < data.length; i++) {
          if (i === 0) {
            this.ObjSpanArr['arr' + key].push(1)
          } else {
            // 判断当前元素与上一个元素是否相同
            if (data[i][key] === data[i - 1][key]) {
              this.ObjSpanArr['arr' + key][this.pos] += 1
              this.ObjSpanArr['arr' + key].push(0)
            } else {
              this.ObjSpanArr['arr' + key].push(1)
              this.pos = i
            }
          }
        }
      },
      handlePriceSet(type) {
        this.$prompt('请输入价格', '批量修改', {closeOnClickModal: false}).then(res => {
          this.param.spu.forEach(v => {
            v[type] = Number(res.value)
            if (type === 'retailPrice' || type === 'costPrice') {
              this.param.spu.forEach(item => {
                const costPrice = Math.round(Number(item.costPrice) * 1e7)
                const retailPrice = Math.round(Number(item.retailPrice) * 1e7)
                item.aftertaxCostPrice = costPrice * (100 + item.taxRate) / (100 * 1e7)
                item.aftertaxRetailPrice = parseFloat(retailPrice * (100 + item.taxRate) / (100 * 1e7))
              })
            }
          })
        }).catch(() => {
          // ...
        })
      },
      // 零售价变更
      handleRetailChange(item) {
        const retailPrice = Math.round(Number(item.retailPrice) * 1e7)
        item.retailPrice = retailPrice / 1e7
        item.aftertaxRetailPrice = parseFloat((retailPrice * (100 + item.taxRate)) / (100 * 1e7))
      },
      // 成本价变更
      handleCostChange(item) {
        const costPrice = Math.round(Number(item.costPrice) * 1e7)
        item.costPrice = costPrice / 1e7
        item.aftertaxCostPrice = parseFloat(costPrice * (100 + item.taxRate) / (100 * 1e7))
      },
      handleEditRate() {
        this.$prompt('请输入税率(百分制计数)', '提示').then(res => {
          const rate = parseFloat(res.value)
          this.param.spu.forEach(item => {
            item.taxRate = rate
            const costPrice = Math.round(Number(item.costPrice) * 1e7)
            const retailPrice = Math.round(Number(item.retailPrice) * 1e7)
            item.aftertaxCostPrice = costPrice * (100 + rate) / (100 * 1e7)
            item.aftertaxRetailPrice = parseFloat(retailPrice * (100 + rate) / (100 * 1e7))
          })
        }).catch(err => {
          console.error(err)
        })
      },
      // 税率改变
      handleRateChange(item) {
        const costPrice = Math.round(Number(item.costPrice) * 1e7)
        const retailPrice = Math.round(Number(item.retailPrice) * 1e7)
        item.aftertaxCostPrice = costPrice * (100 + item.taxRate) / (100 * 1e7)
        item.aftertaxRetailPrice = parseFloat(retailPrice * (100 + item.taxRate) / (100 * 1e7))
      },
      // 含税零售价变更
      handleAfterRetailChange(item) {
        const aftertaxRetailPrice = Math.round(Number(item.aftertaxRetailPrice) * 1e7)
        item.retailPrice = parseFloat((((aftertaxRetailPrice / (100 + item.taxRate)) * 100) / 1e7).toFixed(7))
      },
      // 含税成本价变更
      handleAfterCostChange(item) {
        const aftertaxCostPrice = Math.round(Number(item.aftertaxCostPrice) * 1e7)
        item.costPrice = parseFloat((((aftertaxCostPrice / (100 + item.taxRate)) * 100) / 1e7).toFixed(7))
      }
    },
    created() {
      this.initAlarmInfo()
      setTimeout(() => {
        if (this.param.uuid) {
          if (this.param.sku && this.param.sku.length) {
            this.skuDetailColumns = this.param.sku
            this.ObjSpanArr = {}
            this.skuDetailColumns.forEach(v => {
              this._GeneratorSpanArr(this.param.spu, v.name)
            })
            this.canEditSpu = false
          } else {
            this.canEditSpu = true
          }
        } else {
          this.canEditSpu = true
        }
        if (this.param.spu && this.param.spu.length) {
          this.handleDragSkuValueEnd()
        }
        this.loading = false
      }, 1000)
      // GetBasicSet().then(res => {
      //   if (res.code === 0) {
      //     this.taxRate = parseFloat(res.data.settingBasic.defaultTaxRate / 100)
      //   }
      // })
    },
    components: {
      // IyyUpload,
      draggable
    }
  }
</script>
<style lang="scss">
  $-interval-small: 10px;
  .sku-wrap {
    padding: $-interval-small;
    background-color: #f8f8f8;

    .el-input__inner {
      height: 36px;
      line-height: 36px;
    }

    .sku-gengertor {
      padding: $-interval-small;
      font-size: 14px;
      margin-bottom: $-interval-small;
      border-bottom: 1px solid $content-border-color;

      li {
        border-bottom: 1px dashed $content-border-color;
        padding-bottom: $-interval-small;
        margin-bottom: $-interval-small;
        position: relative;

        &.dragging {
          opacity: 1;
        }

        &.ghost {
          opacity: 0;
        }

        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }

        .close {
          line-height: 1;
          font-size: 20px;
          position: absolute;
          right: 0px;
          top: 0px;
          text-align: center;
          cursor: pointer;

          &:hover {
            color: $theme-color;
          }
        }
      }

      .sku-key {
        margin-bottom: $-interval-small;
      }

      .el-input {
        margin-right: $-interval-small;
      }
    }

    .sku-value-item {
      display: inline-block;
      margin-right: $-interval-small;
      background-color: #fff;
      border-radius: 4px;
      height: 36px;

      &.ghost {
        opacity: 0;
      }

      .el-input {
        margin-right: 5px;
      }

      .sku-value-close {
        margin-right: 5px;
        line-height: 32px;
        cursor: pointer;

        &:hover {
          color: $theme-color;
        }
      }

      .handle {
        cursor: pointer;
        margin-right: 5px;
      }
    }
  }
</style>
