<template>
  <div class="goods-publish">
    <step-container :name="name ? name:'新增商品'"></step-container>
    <el-form v-loading="loading" label-width="160px" :model="form" class="goods-publish-form"
             @submit.native.prevent>
      <el-tabs class="yk-tabs" v-model="activeName" type="card" @tab-click="handleTabChange">
        <el-tab-pane label="基本信息" name="1">
          <addBasic v-model="form"/>
        </el-tab-pane>
        <el-tab-pane lazy label="价格库存" name="2" :disabled="step<1 || this.form.uuid">
          <addPrice v-model="form" ref="addPrice"/>
        </el-tab-pane>
        <el-tab-pane lazy label="订单配送" name="3" :disabled="step<2 || this.form.uuid">
          <addLogistic v-model="form"/>
        </el-tab-pane>
      </el-tabs>
      <div class="goods-publish-btn">
        <el-button @click="handlePre" v-if="step >0">上一步</el-button>
        <el-button type="primary" @click="handleNext" v-if="step <2">下一步</el-button>
        <el-button type="primary" @click="handleCommit" v-if="step ===2 || this.form.uuid">提交</el-button>
      </div>
    </el-form>
  </div>

</template>
<script type="text/javascript">
  import addBasic from "./components/addBasic.vue";
  import addPrice from "./components/addPrice.vue";
  import addLogistic from "./components/addLogistic.vue";
  import {checkObj} from "@/utils/check";

  import {fillObj} from "@/utils/check";

  export default {
    components: {
      addBasic,
      addPrice,
      addLogistic,
    },
    data() {
      return {
        load: false,
        step: 0,
        activeName: '1',
        name: '',
        form: {
          name: '',
          description: '',
          sortUuid: '',
          sortName: '',
          slides: [],
          thumb: '',
          isVideo: 1,
          videoCover: '',
          video: '',
          tags: '',
          goodsType: 3,
          params: [],
          unit: '杯',
          price: 0,
          linePrice: 0,
          costPrice: 0,
          saleStatus: 1,
          stocks: 0,
          reduceType: 2,
          ftType: 3,
          ftPrice: 0,
          limitBuyStatus: 1,
          limitBuyPeriod: '',
          limitBuyValue: 0,
          minNumber: 0,
          virtualSale: 0,
          sort: 0,
          shopOnly: 1,
          shopUse: 1,
          drinkMd5: '',
          drinkName: '',
        },
        checkRules: [
          [
            {value: "name", message: "请填写商品名称"},
            {value: "slides", message: "请选取轮播图"},
            {value: "sortUuid", message: "请选取分类"},
          ],
          [
            {value: "params", message: "请设置标签"},
            {value: "drinkMd5", message: "请选取商品需绑定的饮品"},
            {value: "stocks", message: "请库存设置"},
          ]
        ],
        loading: false
      }
    },
    async mounted() {
      let uuid = this.$route.params.uuid
      if (uuid) {
        this.$api('mkt.goods.detail', {uuid: uuid}).then((res) => {
          this.form = res
          this.name = res.name
          this.form.linePrice /= 100
          this.form.costPrice /= 100
          this.form.ftPrice /= 100
        })
      }

    },
    methods: {
      handleTabChange() {
        this.step = parseInt(this.activeName) - 1
      },
      handleNext() {
        if (this.checkRules.length > this.step) {
          let check = checkObj(this.form, this.checkRules[this.step])
          if (!check.status) {
            this.$message.warning(check.message)
            return
          }
        }

        if (this.step === 0 && this.form.isVideo === 2) {
          let check = checkObj(this.form, [
            {value: "video", message: "请设置视频"},
            {value: "videoCover", message: "请设置视频封面图"},
          ])
          if (!check.status) {
            this.$message.warning(check.message)
            return
          }
        }
        this.step = this.step + 1
        this.activeName = (this.step + 1) + ''
      },
      handlePre() {
        this.step = this.step - 1
        this.activeName = (this.step + 1) + ''
      },
      handleCommit() {
        this.form.thumb = this.form.slides[0]
        let goods = {
          uuid: '',
          name: '',
          description: '',
          sortUuid: '',
          sortName: '',
          slides: [],
          thumb: '',
          isVideo: 1,
          videoCover: '',
          video: '',
          tags: '',
          goodsType: 3,
          params: [],
          unit: '杯',
          price: 0,
          linePrice: 0,
          costPrice: 0,
          saleStatus: 1,
          stocks: 0,
          reduceType: 2,
          ftType: 3,
          ftPrice: 0,
          limitBuyStatus: 1,
          limitBuyPeriod: '',
          limitBuyValue: 0,
          minNumber: 0,
          virtualSale: 0,
          sort: 0,
          shopOnly: 1,
          shopUse: 1,
          drinkMd5: '',
          drinkName: '',
        }
        fillObj(goods, this.form)
        goods.linePrice *= 100
        goods.costPrice *= 100
        goods.ftPrice *= 100
        this.$api(this.form.uuid?'mkt.goods.update':'mkt.goods.create', {}, goods).then(() => {
          this.$message.success(goods.uuid?'更新成功':'创建成功')
          this.$router.back()
        })
      }
    }
  };
</script>
<style lang="scss">
  .goods-publish {
    .el-input, .el-textarea {
      max-width: 500px;
    }

    .el-form-item {
      margin-bottom: 24px;

      .el-form-item__content {
        line-height: 32px;
      }

      .el-form-item__label {
        line-height: 32px;
        font-size: 13px;
      }

      .el-input--small {
        font-size: 12px;
      }

      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }

      .el-input__icon {
        line-height: 32px;
      }
    }

    .yk-tabs {
      min-height: 424px;

      .el-tabs__content {
        min-height: 424px;
      }
    }

    &-form {
      margin: $middle-space;
      margin-bottom: 0;
    }

    &-btn {
      height: 56px;
      padding-bottom: $middle-space;
      background: white;
      text-align: center;
    }
  }
</style>
