<template>
  <div class="select">
    <div class="select-container">
      <p v-for="(item, i) in options"
         @mouseover="idx = i"
         :key="item.uuid" class="select-container-item item"
         :class="{active: i === idx}">
        <el-checkbox v-model="item.checked" @click.native="handleSelect(item)"
                     :checked="selectUUID === item.uuid"
                     :disabled="editUnable && selectUUID !=='' &&selectUUID !== item.uuid">
          {{item.name}}
        </el-checkbox>
        <i class="el-icon-arrow-right" v-if="item.children && item.children.length > 0"></i>
      </p>
    </div>
    <div class="select-sort" v-if="options.length > 0 && options[idx].children && options[idx].children.length > 0">
      <el-row v-for="item in options[idx].children" :key="item.uuid">
        <p><span style="font-size: 12px">{{options[idx].name}}</span> / <b>{{item.name}}</b></p>
        <el-col :span="8" v-for="sort in item.children" :key="sort.uuid" class="item">
          <el-checkbox @click.native="handleSelect(sort)" v-model="sort.checked"
                       :disabled="editUnable && selectUUID !=='' &&selectUUID !== sort.uuid">{{sort.name}}
          </el-checkbox>
          <!--          <span class="text">{{sort.name}}</span>-->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
  export default {
    name: '',
    props: {
      editUnable: {
        type: Boolean,
        default: true,
      },
      shopUse: {
        type: Number,
        default: 1,
      },
      select: String,
    },
    mounted: function () {
      this.selectUUID = this.select
      this.getSorts()
    },
    data: function () {
      return {
        selectUUID: '',
        idx: 0,
        options: [],
      }
    },
    methods: {
      handleSelect(v) {
        if (!v.checked) {
          this.selectUUID = v.uuid
          this.$emit('select', {uuid: v.uuid, name: v.name})
        } else {
          this.selectUUID = ''
          this.$emit('select', {uuid: '', name: ''})
        }
      },
      getSorts() {
        this.$api('mkt.goods.sorts',{shopUse: this.shopUse}).then(res => {
          this.options = res || []
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .select {
    display: flex;

    .item {
      position: relative;
      height: 36px;
      line-height: 36px;

      .text {
        margin-left: $small-space;
        cursor: pointer;
      }
    }

    .active {
      color: $theme-color;
    }

    .item:hover {
      color: $theme-color;
    }

    &-container {
      height: 160px;
      overflow-y: scroll;
      width: 120px;
      padding: 4px;
      margin-right: $small-space;


      &-item {


        i {
          position: absolute;
          top: 12px;
          right: 0;
        }
      }


    }

    &-sort {
      border-left: 1px solid $content-border-color;
      height: 160px;
      overflow-y: scroll;
      flex: 1;
      min-width: 240px;
      padding: 4px;
    }
  }
</style>
