<template>
  <div class="add-price">
    <el-form-item>
      <span class="yk-label__require" slot="label">规格选择</span>
      <el-radio-group v-model="value.goodsType">
        <!--        <el-radio :label="1">单规格</el-radio>-->
        <!--        <el-radio :label="2">多规格</el-radio>-->
        <el-radio :label="3">设备专用</el-radio>
      </el-radio-group>
    </el-form-item>
    <template v-if="value.goodsType === 1">
      <el-form-item>
        <span class="yk-label__require" slot="label">规格值</span>
        <el-input v-model="value.param.param_data[0].value[0].value" size="small" @input="paramValueChange"></el-input>
      </el-form-item>
      <el-form-item>
        <span class="yk-label__require" slot="label">商品价格</span>
        <el-input v-model="value.price" :maxlength="8" size="small">
          <template slot="append">元</template>
        </el-input>
      </el-form-item>
    </template>
    <el-form-item v-if="value.goodsType === 2">
      <span class="yk-label__require" slot="label">商品规格</span>
      <specification @change="handleChange" v-model="value.param" ref="specification"></specification>
    </el-form-item>
    <template v-if="value.goodsType === 3">
      <el-form-item>
        <span class="yk-label__require" slot="label">绑定饮品</span>
        <el-select v-model="value.drinkMd5"
                   @change="handleLoadDrinkParams"
                   filterable placeholder="请选择">
          <el-option
            v-for="item in drinks"
            :key="item.md5"
            :label="item.name"
            :value="item.md5">
          </el-option>
        </el-select>
        <span class="yk-tip">饮品加入门店时, 请确保设备存在对应饮品</span>
      </el-form-item>
      <el-form-item>
        <span class="yk-label__require" slot="label">设置标签</span>
        <params class="add-price-params" @drag="(v) =>{value.params = v}" :params="value.params"></params>
      </el-form-item>

    </template>


    <el-form-item v-if="value.goodsType ===2" label="划线价:">
      <el-input v-model.number="value.linePrice" type="number" :maxlength="8" size="small">
        <template slot="append">元</template>
      </el-input>
      <p class="yk-tip" style="padding-left: 0;margin:0;">当商品无任何优惠时，显示划线价。划线价不代表成交价</p>
    </el-form-item>

    <el-form-item v-if="value.goodsType ===3" label="基础价:">
      <el-input v-model.number="value.linePrice" type="number" :maxlength="8" size="small">
        <template slot="append">元</template>
      </el-input>
      <span class="yk-tip" style="margin:8px;">（商品最小单价，选用其他配料时在此基础上增加价格）</span>
    </el-form-item>

    <template v-if="value.goodsType !== 2">
      <el-form-item label="成本价" size="small" :maxlength="10">
        <el-input v-model.number="value.costPrice" type="number">
          <template slot="append">元</template>
        </el-input>
        <p class="yk-tip">设置成本价格，可以用作成本核算</p>
      </el-form-item>
      <el-form-item>
        <span class="yk-label__require" slot="label">库存</span>
        <el-input v-model.number="value.stocks" size="small"></el-input>
        <p class="yk-tip">库存等于0,商品自动进入售罄模式</p>
      </el-form-item>
    </template>
    <template v-if="value.goodsType === 1">
      <el-form-item label="重量">
        <el-input v-model="value.param.goods_data[0].weight" size="small">
          <template slot="append">KG</template>
        </el-input>
      </el-form-item>
      <el-form-item label="货号">
        <el-input v-model="value.param.goods_data[0].goods_sn" size="small"></el-input>
      </el-form-item>
    </template>
    <template v-if="value.goodsType !== 2">
      <el-form-item>
        <span class="yk-label__require" slot="label">单位</span>
        <el-input v-model="value.unit" v-on:input="value.unit=unitCheck(value.unit)" size="small"></el-input>
      </el-form-item>
    </template>


    <el-form-item label="虚拟已售">
      <el-input v-model.number="value.virtualSale" size="small"></el-input>
    </el-form-item>
  </div>
</template>
<script>
  import specification from "./specification";
  import params from "./params";

  export default {
    props: {
      value: {
        type: Object
      }
    },
    data() {
      return {
        drinks: [],
      }
    },
    mounted() {
      this.$api('equipment.device.orgDrinks').then(data => {
        this.drinks = data || []
      })
    },
    methods: {
      unitCheck(unit) {
        unit = unit.substr(0, 2);
        return unit.replace(/[0-9]/g, '');
      },
      handleLoadDrinkParams() {
        this.$api('equipment.device.drinkSpec', {uuid: this.value.drinkMd5}).then(data => {
          let paramIdx = this.value.params.findIndex((item) => {
            return item.name === '杯量'
          })

          data = data || []
          let param = {
            name: '杯量',
            limit: true,
            items: []
          }

          data.forEach((i, idx) => {
            param.items.push({
              tagIdx: -1,
              name: i.name,
              plusPrice: 0,
              limit: [],
              idx: -1,
              recommend: 1 === idx
            })
          })

          if (paramIdx >= 0) {
            let items = this.value.params[paramIdx].items || []
            items.forEach((item) => {
              if (item.plusPrice > 0) {
                param.items.forEach((pi) => {
                  if (item.name === pi.name) {
                    pi.plusPrice = item.plusPrice
                  }
                })
              }
            })
            this.value.params[paramIdx] = param
          } else {
            this.value.params.push(param)
          }
        })
      }

    },
    components: {
      specification,
      params
    }
  }
</script>
<style lang="scss" scoped>
  .add-price {
    .yk-tip {
      margin-left: $middle-space;
    }


    .el-input, .el-textarea {
      max-width: 280px;
    }
  }
</style>
