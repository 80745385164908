<template>
  <div class="params">
    <p>
      <el-popover v-model="tag.show"
                  placement="top"
                  trigger="hover"
                  width="320">
        <div>
          <el-form label-width="80px">
            <el-form-item
              style="margin-bottom: 4px"
              label="标签名称">
              <el-input v-model="tag.name" placeholder="请输入标签名称" size="small" maxlength="10" show-word-limit/>
            </el-form-item>

            <el-form-item style="margin-bottom: 0">
              <el-button type="primary" size="mini" style="margin-left: 16px"
                         @click="handleNewTag">新增
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <el-button slot="reference" size="small" type="primary" @click="tag.show=true">新增标签</el-button>
      </el-popover>
      <span class="yk-tip">
        (<i class="el-icon-rank"/>) 点击可拖动标签、 ¥ 加价  <i class="el-icon-star-on"/>推荐
         <i class="el-icon-connection"/>限制可用、点击可编辑参数
      </span>
    </p>
    <el-row :gutter="20">
      <el-col :span="16">
        <template v-show="tags.length > 0">
          <el-row class="params-title">
            <el-col :span="4" class="col-padding">
              主标签
            </el-col>
            <el-col :span="20" class="col-padding">参数</el-col>
          </el-row>
          <draggable v-model="tags" @change="handleDragEnd">
            <el-row class="params-item" v-for="(tag, idx) in tags" :key="idx">
              <el-col :span="4" class="col-padding">
                <p>{{tag.name}} <i class="el-icon-rank"></i></p>
                <el-popover v-model="tag.show"
                            placement="top"
                            trigger="hover"
                            width="240">
                  <div>
                    <el-form label-width="80px">
                      <el-form-item
                        style="margin-bottom: 4px"
                        label="标签名称">
                        <el-input v-model="tag.name" size="small" maxlength="10" show-word-limit/>
                      </el-form-item>

                      <el-form-item style="margin-bottom: 0">
                        <el-button type="primary" size="mini" style="margin-left: 16px"
                                   @click="tag.show=false">保存
                        </el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <el-button type="text" size="mini" slot="reference" @click="tag.show=true"><i class="el-icon-edit"></i>
                    编辑
                  </el-button>
                </el-popover>
                <el-button type="text" size="mini" style="margin-left: 8px" v-if="!tag.limit"
                           @click="tags.splice(idx, 1)">
                  <span class="yk-delete"><i class="el-icon-delete"></i> 移除</span></el-button>
              </el-col>
              <el-col :span="20" class="col-padding">
                <el-row style="text-align: center">
                  <draggable v-model="tag.items" @change="handleDragEnd">
                    <el-col :span="8" v-for="(item, _idx) in tag.items" :key="_idx"
                            @click.native="handleAddItem(idx, _idx)">
                      <p class="param param-bg">
                        <i class="el-icon-star-on" v-if="item.recommend"></i>
                        <i class="el-icon-connection" v-if="item.limit && item.limit.length > 0"></i>
                        <span class="param-text">{{item.name}}</span>
                        <span class="param-price" v-if="item.plusPrice > 0"> ¥ </span>
                        <i class="el-icon-rank opr"></i>
                      </p>
                    </el-col>
                  </draggable>
                  <el-col :span="8" v-if="!tag.limit" @click.native="handleAddItem(idx, -1)">
                    <p class="param param-add"><i class="el-icon-plus"></i> 新增参数</p>
                  </el-col>
                </el-row>
              </el-col>

            </el-row>
          </draggable>
        </template>
        <empty-tip v-if="tags.length === 0">
          <p class="yk-tip">暂无标签，点击上方【新增标签】完成标签新增</p>
        </empty-tip>
      </el-col>
      <el-col :span="8" v-show="tags.length > 0">
        <p style="margin-top: 8px;margin-bottom: 8px">预览</p>
        <div class="params-preview">
          <div v-for="(tag, idx) in tags" :key="idx" class="params-preview-item">
            <p>{{tag.name}}</p>
            <div>
             <span v-for="(item, _idx) in tag.items" :key="_idx"
                   :class="{active : item.recommend}" class="params-preview-tag">
               <i class="el-icon-star-on" v-if="item.recommend"></i>
               {{item.name}}
               <template v-if="item.recommend">(推荐)</template>
             </span>
            </div>
          </div>

        </div>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="editItemVisible" :title="item.name || '新增参数'">
      <el-form label-width="80px">
        <el-form-item>
          <span class="yk-label__require" slot="label">参数名称</span>
          <el-input v-model="item.name" size="small" placeholder="请输入参数名称" maxlength="12" show-word-limit/>
        </el-form-item>

        <el-form-item
          label="默认推荐">
          <el-switch v-model="item.recommend"/>
        </el-form-item>

        <el-form-item
          label="加价">
          <el-input-number v-model.number="item.plusPrice" size="small">
          </el-input-number>
          （元）
        </el-form-item>

        <el-form-item
          label="限定选取">
          <el-checkbox-group v-model="item.limit">
            <div v-for="(tag, idx) in tags" :key="idx">
              <template v-if="idx !== item.tagIdx">
                <div class="tag-name">{{tag.name}}</div>
                <el-checkbox v-for="(item, _idx) in tag.items" :label="tag.name + '_' + item.name"
                             :key="item.name + _idx">{{item.name}}
                </el-checkbox>
              </template>

            </div>
          </el-checkbox-group>
        </el-form-item>

        <el-form-item style="margin-bottom: 0">
          <el-button type="primary" size="mini" style="margin-left: 16px"
                     @click="handleParamEdit">保存
          </el-button>
        </el-form-item>


      </el-form>
    </el-dialog>
  </div>

</template>

<script>
  import draggable from 'vuedraggable'

  export default {
    name: '',
    components: {
      draggable
    },
    props: {
      params: {
        type: Array,
        default: () => {return {}}
      }
    },
    mounted: function () {
      this.tags = this.params
    },
    data: function () {
      return {
        editItemVisible: false,
        item: {
          tagIdx: -1,
          idx: -1,
          name: '',
          plusPrice: 0,
          recommend: false,
          limit: []
        },
        tag: {
          name: '',
          show: false,
        },
        tags: [
        ]
      }
    },
    methods: {
      handleDragEnd() {
        this.$emit('drag', this.tags)
      },
      handleAddItem(tagIdx, idx) {
        this.item.tagIdx = tagIdx
        this.item.idx = idx
        if (idx !== -1) {
          let item = this.tags[tagIdx].items[idx]
          this.item.name = item.name
          this.item.plusPrice = item.plusPrice / 100
          this.item.recommend = item.recommend
          this.item.limit = item.limit || []
        } else {
          this.item = {
            tagIdx: tagIdx,
            idx: -1,
            name: '',
            plusPrice: 0,
            recommend: false,
            limit: []
          }
        }

        this.editItemVisible = true
      },
      handleNewTag() {
        if (!this.tag.name) {
          this.$message.warning('请输入标签名称')
          return
        }
        this.tags.unshift({name: this.tag.name, show: false, items: []})
        this.tag.show = false
        this.tag.name = ''
      },
      handleParamEdit() {
        if (!this.item.name) {
          this.$message.warning('请输入参数名称')
          return
        }
        // this.tags[this.item.tagIdx].items = this.tags[this.item.tagIdx].items || []
        if (this.item.recommend) {
          (this.tags[this.item.tagIdx].items).forEach(item => {
            item.recommend = false
          })
        }

        if (this.item.idx !== -1) {
          this.tags[this.item.tagIdx].items[this.item.idx].recommend = this.item.recommend
          this.tags[this.item.tagIdx].items[this.item.idx].name = this.item.name
          this.tags[this.item.tagIdx].items[this.item.idx].plusPrice = this.item.plusPrice * 100
          this.tags[this.item.tagIdx].items[this.item.idx].limit = this.item.limit
        } else {
          this.tags[this.item.tagIdx].items.push({
            name: this.item.name,
            plusPrice: this.item.plusPrice * 100,
            limit: this.item.limit,
            recommend: this.item.recommend,
          })
        }


        this.editItemVisible = false
      },
    },


  }
</script>

<style lang="scss" scoped>
  .params {
    &-title {
      background: #f8f8f8;
      margin-top: $middle-space;

      .el-col {
        color: black;
      }
    }

    .yk-tip{
      margin-left: $middle-space;
    }
    &-item {
      padding-top: $middle-space;
      padding-bottom: $small-space;
      border: 1px solid $content-border-color;
      border-top: none;
      cursor: pointer;
    }

    .param-bg {
      background: #f8f8f8;
    }

    &-preview {
      background: #fafafa;
      border-radius: 4px;
      min-height: 280px;
      padding: $middle-space;

      &-item {
        margin-bottom: $middle-space;
        font-size: 12px;
      }

      &-tag {
        margin-left: $small-space;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        padding: 0 4px;
        margin-bottom: 4px;
        border-radius: 4px;
        background: #efefef;
        font-size: 12px;

      }

      .active {
        background: #ffe2bc;
        color: #f37746;
      }
    }


    .param {
      margin-right: $small-space;
      margin-bottom: $small-space;
      padding-left: 4px;
      border-radius: 4px;
      font-size: 12px;
      color: $color-black;
      border: 1px solid transparent;
      cursor: pointer;

      &-text {

      }

      .opr {
        font-size: 13px;
        float: right;
        margin-top: $small-space;
        margin-left: $small-space;
        margin-right: $small-space;
      }
    }

    .param-add {
      border: 1px solid $theme-color;
      color: $theme-color;
    }

    .param:after {
      display: block;
      content: " ";
      clear: both;
    }

    .col-padding {
      text-align: left;
      padding-left: $middle-space;
    }

    .tag-name {
      font-size: 14px;
      color: $color-black;
    }
  }
</style>
